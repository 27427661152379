import React, { useState, useRef, Fragment, useEffect } from "react"
import styled from "styled-components"
import io from "socket.io-client"
import { get } from "lodash"
import ReactTooltip from "react-tooltip"

import LockLogoSrc from "../../imgs/lock.svg"
import ConfirmModal from "../ConfirmModal/ConfirmModal"

import { updateProxyCredential } from "../../utils/CallAPIs"
import useToastMessage from "../../hooks/useToastMessage"
import authStore from "../../stores/auth"

const ProxyInfoTable = styled.table`
  margin: 0 auto;
  min-width: min(400px, calc(100% - 20px));

  @media (min-width: 961px) {
    width: 100%;
  }

  tr {
    margin-bottom: 10px;
  }

  td {
    font-size: 14px;
    padding-bottom: 5px;

    &:nth-child(1) {
      padding-right: 14px;
    }

    &:nth-child(2) {
      text-align: right;
      cursor: text;
      user-select: text;
    }
    &:nth-child(3) {
      width: 25%;
      color: #6579f3;
      text-align: right;
      cursor: pointer;
      padding-left: 5%;
      @media screen and (max-width: 1023px) {
        padding-left: 2px;
      }
    }
    input {
      max-width: 100%;
      border: 0.25px solid #9ba1aa;
      border-radius: 4px;
      padding: 5px;
    }
  }
  .submitting {
    pointer-events: none;
  }
  .toast-message {
    text-align: center;
    color: #6abf83;
    max-width: 50px;
    @media (min-width: 350px) {
      max-width: 15ch;
    }
    @media (min-width: 480px) {
      max-width: 20ch;
    }
    @media (min-width: 768px) {
      max-width: 250px;
    }
    overflow-wrap: break-word;
    &--error {
      color: red;
    }
  }
  .hidable {
    max-width: 50px;
    @media (min-width: 350px) {
      max-width: 15ch;
    }
    @media (min-width: 480px) {
      max-width: 20ch;
    }
    @media (min-width: 768px) {
      max-width: 250px;
    }
    overflow-wrap: break-word;
  }
`
const Icon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  ${props => (!props.isTrial ? "display: none;" : "")}
  @media screen and (max-width: 1023px) {
    margin-left: 2px;
  }
`

const TYPE = {
  PASSWORD: "Password",
  USERNAME: "Username",
}
const MAX_LENGTH = {
  [TYPE.PASSWORD]: 16,
  [TYPE.USERNAME]: 24,
}

const validateData = (input, concat = false, type = TYPE.PASSWORD) => {
  const errorArray = []
  if (String(input).length < 8) {
    errorArray.push(`${type} should have at least 8 characters in length`)
  }
  if (String(input).length > MAX_LENGTH[type]) {
    errorArray.push(
      `${type} should have maximum ${MAX_LENGTH[type]} characters in length`
    )
  }

  if (type === TYPE.PASSWORD && /[^a-zA-Z0-9]/g.test(input)) {
    errorArray.push(
      `${type} should only contain uppercase, lowercase letters and digit (0-9)`
    )
  }

  if (type === TYPE.USERNAME && !/[a-zA-Z]/g.test(input)) {
    errorArray.push(`${type} must contain letters`)
  }

  if (errorArray.length === 0) return false
  return concat ? errorArray.join(", ") : errorArray[0]
}

const ProxyInfo = ({
  proxyIp,
  httpPort,
  httpsPort,
  modemGroup,
  proxyUsername,
  proxyPassword,
  subId,
  reload,
  socksPort,
  isTrial,
  pgConnection = null,
  multilocationProduct = null,
}) => {
  const [changeUsername, setChangeUserStatus] = useState(false)
  const [changePassWord, setChangEPassWordStatus] = useState(false)
  const [userNameError, setUserError] = useState("")
  const [showModal, setModalStatus] = useState(false)
  const [pwError, setPWError] = useState("")
  const [toastConfig, displayToast] = useToastMessage()
  const [submitting, setSubmitting] = useState(false)
  const usernameInputRef = useRef(null)
  const pwInputRef = useRef(null)
  const confirmPwInputRef = useRef(null)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const onHide = () => setModalStatus(false)
  const onShow = () => setModalStatus(true)

  const _onchangePassword = event => {
    const validatePWError = validateData(event.target.value, true)
    if (validatePWError) {
      ReactTooltip.show(pwInputRef.current)
      setPWError(validatePWError)
    }
    if ((pwError && !validatePWError) || !pwInputRef.current) {
      ReactTooltip.hide(pwInputRef.current)
      setPWError("")
    }
  }

  const _onchangeUserName = event => {
    const validateUsernameError = validateData(
      event.target.value,
      true,
      "Username"
    )
    if (validateUsernameError) {
      ReactTooltip.show(usernameInputRef.current)
      setUserError(validateUsernameError)
    }
    if (
      (usernameInputRef && !validateUsernameError) ||
      !usernameInputRef.current
    ) {
      ReactTooltip.hide(usernameInputRef.current)
      setUserError("")
    }
  }

  const _getButtonText = editing => (editing ? "Cancel" : "Change")

  const _toggleChangeUsername = () => {
    if (isTrial) {
      return onShow()
    }
    setSubmitting(false)
    setChangeUserStatus(currentStatus => !currentStatus)
  }

  const _toggleChangePassword = () => {
    if (isTrial) {
      return onShow()
    }
    setSubmitting(false)
    setChangEPassWordStatus(currentStatus => !currentStatus)
  }

  const _handleFinish = () => {
    setChangeUserStatus(false)
    setChangEPassWordStatus(false)
    setSubmitting(false)
    displayToast({ message: "Save Success!" })
    reload(new Date())
  }

  const _handleError = () => {
    setChangeUserStatus(false)
    setChangEPassWordStatus(false)
    setSubmitting(false)
    displayToast({ message: "Save Fail ! Please try again", error: true })
  }

  const _getErrorMessage = error => {
    if (error.response) {
      return error.response?.data?.errors[0]?.title || error.message
    }
    return error.message
  }

  const _listenToSocket = () => {
    const userID = get(authStore, "state.user.id")
    const socket = io.connect(process.env.RP_SOCKET_URL, {
      query: `userID=${userID}`,
    })

    socket.on("message", data => {
      if (data.last_apply_settings_success || data.last_apply_setting) {
        _handleFinish()
      } else {
        _handleError()
      }
      socket.close()
    })
  }

  const _handleSaveUserName = async () => {
    try {
      const newUsername = usernameInputRef.current.value
      const validateUsernameError = validateData(newUsername, false, "Username")
      if (validateUsernameError) {
        return displayToast({
          message: validateUsernameError,
          error: true,
          time: 5000,
        })
      }
      const res = await updateProxyCredential({
        id: subId,
        username: newUsername,
        password: proxyPassword,
      })
      displayToast({
        message: res?.data?.message,
        time: 20000,
      })
      setSubmitting(true)
      _listenToSocket()
    } catch (error) {
      setSubmitting(false)
      displayToast({
        message: _getErrorMessage(error),
        error: true,
        time: 5000,
      })
    }
  }

  const _handleSavePassword = async () => {
    try {
      const newPW = pwInputRef.current.value
      const confirmPW = confirmPwInputRef.current.value
      const validatePWError = validateData(newPW)
      if (validatePWError) {
        return displayToast({
          message: validatePWError,
          error: true,
          time: 5000,
        })
      }
      if (!confirmPW || newPW !== confirmPW)
        return displayToast({
          message: !newPW
            ? "Password can not be empty"
            : "Confirm password is not match",
          error: true,
          time: 5000,
        })
      const res = await updateProxyCredential({
        id: subId,
        username: proxyUsername,
        password: newPW,
      })
      setSubmitting(true)
      displayToast({
        message: res?.data?.message,
        time: 20000,
      })
      _listenToSocket()
    } catch (error) {
      setSubmitting(false)
      displayToast({
        message: _getErrorMessage(error),
        error: true,
        time: 5000,
      })
    }
  }

  const saveText = submitting ? "Saving" : "Save"

  let currentHttpPort = httpPort
  let currentIp = proxyIp
  let currentUsername = proxyUsername
  let currentPassword = proxyPassword
  let currentSocksPort = socksPort
  let currentHttpsPort = httpsPort
  if (multilocationProduct && pgConnection) {
    currentHttpPort = pgConnection?.httpPort
    currentIp = pgConnection?.ip
    currentUsername = pgConnection?.username
    currentPassword = pgConnection?.password
    currentSocksPort = pgConnection?.socksPort
    currentHttpsPort = pgConnection?.httpsPort
  }

  return (
    <>
      <div>
        <hr />
      </div>
      <h5>Login Info</h5>
      <ProxyInfoTable>
        <tbody>
          <tr>
            <td>Username</td>
            <td className="hidable">{currentUsername}</td>
            <td onClick={_toggleChangeUsername}>
              {_getButtonText(changeUsername)}
              <Icon src={LockLogoSrc} alt="" isTrial={isTrial} />
            </td>
          </tr>
          {changeUsername && (
            <tr>
              <td>New username</td>
              <td>
                <input
                  ref={usernameInputRef}
                  type="text"
                  onChange={_onchangeUserName}
                  data-tip={userNameError}
                  data-event="focus"
                  data-multiline={true}
                  data-effect="solid"
                />
              </td>
              <td onClick={_handleSaveUserName}>{saveText}</td>
            </tr>
          )}
          <tr>
            <td>Password</td>
            <td className="hidable">{currentPassword}</td>
            <td onClick={_toggleChangePassword}>
              {_getButtonText(changePassWord)}
              <Icon src={LockLogoSrc} alt="" isTrial={isTrial} />
            </td>
          </tr>
          {changePassWord && (
            <>
              <tr>
                <td>New password</td>
                <td>
                  <input
                    ref={pwInputRef}
                    type="password"
                    onChange={_onchangePassword}
                    data-tip={pwError}
                    data-event="focus"
                    data-multiline={true}
                    data-effect="solid"
                  />
                </td>
              </tr>
              <tr>
                <td>Confirm password</td>
                <td>
                  <input ref={confirmPwInputRef} type="password" />
                </td>
                <td
                  onClick={_handleSavePassword}
                  className={submitting ? "submitting" : ""}
                >
                  {saveText}
                </td>
              </tr>
            </>
          )}
          {toastConfig.message && (
            <tr>
              <td
                colspan="3"
                className={[
                  "toast-message",
                  toastConfig.error ? "toast-message--error" : "",
                ].join(" ")}
              >
                {toastConfig.message}
              </td>
            </tr>
          )}
        </tbody>
      </ProxyInfoTable>
      <div>
        <hr />
      </div>
      <h5>HTTP Proxy Info</h5>
      <ProxyInfoTable>
        <tbody>
          <tr>
            <td>Proxy IP</td>
            <td>{currentIp}</td>
          </tr>
          <tr>
            <td>HTTP port</td>
            <td>{currentHttpPort}</td>
          </tr>
        </tbody>
      </ProxyInfoTable>
      <div>
        <hr />
      </div>
      <h5>HTTPS Proxy Info</h5>
      <ProxyInfoTable>
        <tbody>
          {modemGroup?.dns && (
            <tr>
              <td>Proxy Hostname</td>
              <td>{modemGroup?.proxyServerHostname}</td>
            </tr>
          )}
          <tr>
            <td>HTTPS port</td>
            <td>{currentHttpsPort}</td>
          </tr>
        </tbody>
      </ProxyInfoTable>
      {socksPort && (
        <>
          <div>
            <hr />
          </div>
          <h5>Socks Proxy Info</h5>
          <ProxyInfoTable>
            <tbody>
              <tr>
                <td>Socks port</td>
                <td>{currentSocksPort}</td>
              </tr>
            </tbody>
          </ProxyInfoTable>
        </>
      )}
      <ConfirmModal show={showModal} onHide={onHide} />
    </>
  )
}

export default ProxyInfo
