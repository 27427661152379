import React, { useState } from "react"
import multilocationExchange from "../imgs/multilocation-exchange.png"
import styled from "styled-components"

const MultiLocationProductDetailHeader = ({
  subscription,
  locations,
  handleSwitch,
  isSwitching,
}) => {
  const [selectedProxyId, setSelectedProxyId] = useState(null)
  const subscribedProxy = subscription.features.find(
    feature => feature.featureType === "Proxy Type"
  )
  return (
    <HeaderContainer>
      <div className="text-center">
        <h4 className="title">USA Location Exchange Product</h4>
        <img src={multilocationExchange} height="55" alt="Exchange"></img>
        <div className="ccc1b">
          {/* <h3 className="ccc1b-h3">{subscription.nickName || proxy.title}</h3> */}
          {subscription.location && (
            <strong>
              {subscription.location.city}, {subscription.location.state}
            </strong>
          )}
          <strong>{subscribedProxy?.title}</strong>
          <strong>
            {subscribedProxy?.servicesOffered.split(",").slice(-1)[0]}
          </strong>
        </div>
        <hr />

        <div className="available-locations-wrapper">
          {locations.map(location => {
            if (!location.typesAvailable) {
              return null
            }

            return location.features.map(proxy => {
              if (!proxy.available) {
                return null
              }
              return (
                <div className="single-location">
                  <div>
                    <span>
                      {location.city},{location.state} |
                    </span>
                    <span>{proxy.title} |</span>
                    <span>{proxy.servicesOffered.split(",").slice(-1)[0]}</span>
                  </div>

                  <span>
                    <button
                      className="switch-button"
                      onClick={() => {
                        setSelectedProxyId(proxy.id)
                        handleSwitch(location.id, proxy.id)
                      }}
                      disabled={isSwitching}
                    >
                      {isSwitching && selectedProxyId === proxy.id
                        ? "Switching..."
                        : "Switch"}
                    </button>
                  </span>
                </div>
              )
            })
          })}
        </div>
      </div>
    </HeaderContainer>
  )
}

export default MultiLocationProductDetailHeader

const HeaderContainer = styled.div`
  margin-top: 2rem;
  .ccc1b {
    font-size: 18px;
    margin-top: 27px;
    margin-bottom: 30px;
  }
  .text-center {
    text-align: center;
    .title {
      color: #419bf9;
      font-size: 20px;
      font-weight: bold;
    }
    .available-locations-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .single-location {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        color: #000;
        font-size: 12px;
        font-weight: bold;
      }
      .switch-button {
        background-color: #8a59ef;
        border-radius: 18px;
        border: none;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        padding: 6px 12px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
        &:disabled {
          background: #b0b0b0;
          transform: scale(0.95);
        }
      }
    }
  }
`
